import React, { useEffect } from 'react';
import classNames from 'classnames'
import Dialog from '@mui/material/Dialog';
import DateRangePicker from 'components/RateInput/RangePicker'
import moment from 'moment'
import { connect } from 'react-redux';

import calendarIcon from '../../assets/images/icon-calendar.svg'
import arrowIcon from '../../assets/images/arrow-down.svg'
import employeeIcon from '../../assets/images/employee-icon.svg'

const EmployeeInputList = (props) => {
  const [openDropdown, setOpenDropdown] = React.useState(false)
  const [value, setValue] = React.useState('Choose Employee')
  const [selectOption, setSelectOption] = React.useState([])
  const {
    onConfirm,
    isDisplayAll,
    auth: {
      authenticated
    },
    news: {
      employeeMetaData
    }
  } = props

  useEffect(()=> {
    setSelectOptionValue()
  }, [employeeMetaData])

  const setSelectOptionValue = () => {
    let data = employeeMetaData.results
    if (isDisplayAll && authenticated?.is_admin && !data.find(e => e.id === null)) data.push({
      id: null, name: 'All Employee'
    })
    setSelectOption(data)
  }


  const handleSetValue = (v) => {
    setValue(v.name)
    setOpenDropdown(false)
    onConfirm(v)
  }
  
  return (
      <>
        <div className='dropdown'>
            <div className='btn' onClick={() => setOpenDropdown(!openDropdown)}>
                <span>
                    <img src={employeeIcon} alt="calendar" />
                </span>
                {value}
                <span className='right-icon'>
                    <img src={arrowIcon} alt="right-icon"/>
                </span>
            </div>
            <div className={classNames({
              'dropdown-menu zIndex999': true,
              'hide': !openDropdown
            })}>
              {
                selectOption.map((d, idx)=> {
                  let handleClick = () => {
                    handleSetValue(d)
                  }
                  return (
                    <div className='dropdown-item' onClick={handleClick} key={idx}>{d.name}</div>
                  )
                })
              }
            </div>
        </div>

      </>
  )
}

EmployeeInputList.defaultProps = {
  // date: '02/16/2022'
  onConfirm: () => false,
  isDisplayAll: false,
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        news: state.news
    }
}
const mapDispatchToProps = null
export default connect(mapStateToProps, mapDispatchToProps)(EmployeeInputList);
