export const routerPath = {
  homepage: {
    root: '/',
  },
  auth: {
    signin: '/auth/login',
    signup: '/auth/signup',
    forgot_password: '/auth/forgot_password',
    token: '/auth/token',
  },
  user: {
    dashboard: '/user/dashboard',
    profile: '/user/profile',
    statistics: '/user/statistics',
    adminstatistics: '/user/admin-statistics',
    adminrate: '/user/adminrate',
    rate: '/user/mood',
    moodDetail: '/user/mood-detail',
    upload: '/user/upload-employee',
  },
}
