import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router";
import { connect } from 'react-redux'

import HeaderBarDashboard from 'components/HeaderBar/Dashboard'
import SideBarDashboard from 'components/SideBar/Dashboard'
import Loading from 'components/Loading'
import { Bar } from 'react-chartjs-2';
import classNames from 'classnames'
import moment from 'moment'
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormHelperText from '@mui/material/FormHelperText';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
import { actionToProps as newsAction } from 'store/reducers/news/news.action'
import { actionToProps as fetchEmployeeStatsAction } from 'store/reducers/news/actions/fetchEmployeeStatsAction'
import RateInputDateRange from 'components/RateInput/DateRange'
import EmployeeInputList from 'components/RateInput/EmployeeList'
import OrganizationDropdownList from 'components/Organization/OptionList';

import SnackbarAlert from 'components/Alert/Snackbar'
import BarChartLegend from 'components/Statistic/BarChartLegend'


const tabs = [{
  value: 'overall',
  label: 'Overall Happiness Score'
},{
  value: 'peremployee',
  label: 'Average Per Employee'
}]

const StatisticsView = (props) => {
    // const [modalNewEmploye, setModalNewEmploye] = useState(false)
    const [activeTab, setActiveTab] = useState(tabs[0])
    const [employeeActive, setEmployee] = useState(null)
    const [filterGraph, filterGraphUpdate] = useState({})
    const [dateActive, setDate] = useState({
      from: moment().format('YYYY-MM-DD'),
      to: moment().add(1, 'day').format('YYYY-MM-DD'),
    })
    const {
        // history,
        fetchEmployeeStats,
        fetchListEmployee,
        changeNewsAttribute,
        news: {
          employeeEmotionStats,
            // employeeEmotionStats: {
            //     // all,
            //     happy
            // },
            errors,
            alertSeverity,
            isPageLoading
        },
        auth: {
          authenticated
        }
    } = props

    const handleInputRangePerEmployee = async date => {
      setDate(date)

      let payload = {
        to_date: date.to,
        from_date: date.from,
        u_id: employeeActive?.id
      }

      const filterPayloadUpdate = {
        ...filterGraph,
        ...payload
      }

      // await fetchEmployeeStats(filterPayloadUpdate)
      filterGraphUpdate(filterPayloadUpdate)
    }
    const handleSelectEmployee = async (empl) => {
      setEmployee(empl)

      let payload = {
        to_date: dateActive.to,
        from_date: dateActive.from,
        u_id: empl.id
      }


      let filterPayloadUpdate = {
        ...filterGraph,
        ...payload
      }

      const { u_id, ...newObject } = filterPayloadUpdate

      // await fetchEmployeeStats(!empl.id ? newObject : filterPayloadUpdate)
      filterGraphUpdate(!empl.id ? newObject : filterPayloadUpdate)
    }


    React.useEffect(() => {
        fetchEmployeeStats()
    }, [])

    const handleChangeOrganization = async org_id => {
      let payload = {
        organization_id: org_id
      }
      
      let filterPayloadUpdate = filterGraph

      if (org_id !== null ) filterPayloadUpdate = {
        ...filterGraph,
        ...payload,
      }
      else {
        const { organization_id, ...cleanOrganization } = filterPayloadUpdate
        filterPayloadUpdate = cleanOrganization
      }

      if (!filterPayloadUpdate.from_date) {
        filterPayloadUpdate = {
          ...filterPayloadUpdate,
          from_date: moment().format('YYYY-MM-DD'),
          to_date: moment(moment(), 'YYYY-MM-DD').add('days', 1).format('YYYY-MM-DD')
        }
      }

      // const { u_id, ...cleanUid } = filterPayloadUpdate
      // const filterStats = activeTab.value === 'overall' ? cleanUid : filterPayloadUpdate

      // if(activeTab.value !== 'overall') {
      //   fetchListEmployee(org_id ? { organization_id: org_id} : null)
      // }

      // await fetchEmployeeStats(filterStats)
      filterGraphUpdate(filterPayloadUpdate)
    }

    const handleInputRangeOverall = async date => {
      let payload = {
        to_date: date.to,
        from_date: date.from,
        // u_id: authenticated.id
      }
      
      const filterPayloadUpdate = {
        ...filterGraph,
        ...payload
      }

      // if (!payload?.to_date){
      //   await fetchEmployeeStats()
      // }else{
      //   await fetchEmployeeStats(filterPayloadUpdate)
      // }
      filterGraphUpdate(filterPayloadUpdate)
    }

    useEffect(()=> {
      if (Object.keys(filterGraph).length) {
        fetchEmployeeStats(filterGraph)
      }

    }, [filterGraph])

    useEffect(()=> {
      let filterWithActiveTab = {
        from_date: moment().format('YYYY-MM-DD'),
        to_date: moment(moment(), 'YYYY-MM-DD').add('days', 1).format('YYYY-MM-DD')
      }

      if(activeTab.value === 'peremployee') {
        filterWithActiveTab = {
          ...filterWithActiveTab,
          total_employee: 1
        }
      }
      filterGraphUpdate(filterWithActiveTab)
    }, [activeTab])
    return (
        <>
            <div className='wrapper'>
                <HeaderBarDashboard />
                <div className="main-wrapper-content">
                    <SideBarDashboard />
                    <div className="content-dash">
                        <div className="card mb-3">
                            <div className='nav-body'>
                              {
                                tabs.map((e, idx)=> {
                                  return (
                                    <div className={classNames({
                                      'nav-link': true,
                                      'active': e.value === activeTab.value
                                    })}
                                      key={idx}
                                      onClick={()=> {
                                        setActiveTab(e)
                                      }}>
                                        {e.label}
                                    </div>
                                  )
                                })
                              }
                            </div>
                            {
                              activeTab.value === 'overall' && (

                                <div className="card-body">
                                        <div className="mui-row d-flex flex-wrap items-center">
                                            <div className="mui-col-md-3">
                                                <h3 className="mb-0 mt-0">Select date rage:</h3>
                                            </div>

                                            <div className="mui-col-md-4 text-right">
                                              <OrganizationDropdownList isDisplayAll onConfirm={handleChangeOrganization}/>
                                            </div>
                                            <div className="mui-col-md-5 text-right">
                                              <RateInputDateRange onConfirm={handleInputRangeOverall}/>

                                            </div>
                                        </div>
                                    <div className="statistic">
                                      <h2 className="mb-5" style={{fontWeight: 'bold'}}>{activeTab.label}</h2>
                                        <p className='mui--text-center text-grey' style={{marginTop: 20}}>Mood Statistics</p>
                                        <div className="chartWrapper">
                                            <div className="chartAreaWrapper">
                                                <div className="chartAreaWrapper2">
                                                </div>
                                            </div>
                                        </div>

                                        <div className='chart-wrapper'>
                                            <div className="chart-container">
                                              {
                                                employeeEmotionStats && (
                                                  <BarChartLegend data={employeeEmotionStats} />
                                                )
                                              }
                                            </div>
                                        </div>

                                    </div>
                                </div>
                              )
                            }
                            {
                              activeTab.value === 'peremployee' && (

                                <div className="card-body">
                                        <div className="mui-row d-flex flex-wrap items-center">
                                          <div className="mui-col-md-3">
                                              <h3 className="mb-0 mt-0">Select date rage:</h3>
                                          </div>
                                          <div className="mui-col-md-4 text-right">
                                            <OrganizationDropdownList isDisplayAll onConfirm={handleChangeOrganization}/>
                                          </div>
                                          <div className="mui-col-md-2 text-right">
                                            <EmployeeInputList isDisplayAll onConfirm={handleSelectEmployee}/>

                                          </div>
                                          <div className="mui-col-md-3 text-right">
                                            <RateInputDateRange onConfirm={handleInputRangePerEmployee}/>


                                          </div>
                                        </div>
                                    <div className="statistic">

                                        <h2 style={{fontWeight: 'bold'}}>{activeTab.label}</h2>
                                        <h2 style={{fontSize: 20, margin: 0}}>{employeeActive?.name || 'Unnamed'}</h2>
                                        <p className='mui--text-center text-grey' style={{marginTop: 20}}>Mood Statistics</p>
                                        <div className="chartWrapper">
                                            <div className="chartAreaWrapper">
                                                <div className="chartAreaWrapper2">
                                                </div>
                                            </div>
                                        </div>

                                        <div className='chart-wrapper'>
                                            <div className="chart-container">
                                              <BarChartLegend data={employeeEmotionStats} />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                              )
                            }
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        news: state.news
    }
}
const mapDispatchToProps = {
    ...fetchEmployeeStatsAction,
    ...newsAction
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StatisticsView))
