import React from 'react';
import classNames from 'classnames'
import { moods } from 'constants/mood-list'

const RateInputMood = (props) => {
  const [active, setActive] = React.useState('')
  const {
    moodList,
    onSelect,
  } = props

  const handleSelect = (m) => {
    setActive(m.id)
    onSelect(m)
  }

  return (
    <div className="rate">
      {
        moodList && moodList.map((m, idx)=> {
          return (
          <div className={classNames({
            "rate-option text-center": true,
            'active': m.id === active
          })} onClick={()=> handleSelect(m)} key={idx}>
              <img src={m.image} alt="" />
              <div className="rate-name">
                  {m.title}
              </div>
          </div>)
        })
      }
    </div>
  )
}

RateInputMood.defaultProps = {
  onSelect: () => false,
  moodList: moods
}

export default RateInputMood;
