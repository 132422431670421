import moment from 'moment'
import {
  CHANGE_ATTRIBUTE as CHANGE_ATTRIBUTE_NEWS
} from 'store/reducers/news/news.constant'
import {
  CHANGE_ATTRIBUTE as CHANGE_ATTRIBUTE_AUTH
} from 'store/reducers/auth/auth.constant'
import {
  // getDateNow,
  apiGetMoodEmployeeDetail,
  apiPostMoodEmployee,
  apiGetProfile,
  apiPutProfile,
  apiGetEmployeeStats,
  apiDeleteEmployee,
  apiPostNewEmployee,
  apiGetEmployee
} from 'api/dashboard.api'
import { errorValidationHandler } from 'utils/errorHandler'
import { moods } from 'constants/mood-list'

export const actionToProps = {
  changeNewsAttribute: ({key, value, type=CHANGE_ATTRIBUTE_NEWS}) => dispatch => {
    return new Promise((resolve, reject)=>{
      dispatch({
        type: type,
        payload: {key: key, value: value}
      })
      resolve(value)
    })
  },
  actionGetMoodEmployeeDetail: (payload=null) => async (dispatch, getState) => {
    const state = getState()
    const {
      auth: {
        authenticated,
      },
      news: {
        employeeMoodDetail
      }
    } = state
    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'isPageLoading',
        value: true
      }
    })

    try {
      const { data } = await apiGetMoodEmployeeDetail(payload)
      let dataResponse = {}
      if(!payload?.id){
        // dataResponse = data?.results.length ? data?.results[0] : 'No log found!.'
        if (data?.results.length === 0){
          dataResponse = {
            message: 'No log found!.',
            mood: 'no-mood',
            date: false
          }
        }else{
          dataResponse = {
            ...data?.results[0],
            message: 'mood found'
          }
        }
        if (data.msg) {
          dataResponse = {
            message: data.msg
          }
        }

        dataResponse = {
          ...dataResponse,
          id: authenticated.id,
          name: authenticated.name,
          email: authenticated.email
        }
      }else {
        if (data.msg) {
          dataResponse = {
            message: data.msg
          }
        }

        if (data.id) {
          dataResponse = data
        }else {
          dataResponse = {
            ...dataResponse,
            mood: 'no-mood'
          }
        }

        dataResponse = {
          ...dataResponse,
          ...payload
        }
      }

      const employeeDetail = {
        ...employeeMoodDetail,
        ...dataResponse
      }

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'employeeMoodDetail',
          value: employeeDetail
        }
      })


      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })

      return data
    } catch (error) {
      const { message } = error
      const errorHandlerData = errorValidationHandler([message])

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'alertSeverity',
          value: 'error'
        }
      })
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'errors',
          value: errorHandlerData
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })

      return false
    }
  },
  actionPostMood: (payload) => async (dispatch, getState) => {
    // const state = getState()
    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'isPageLoading',
        value: true
      }
    })

    try {
      const { data } = await apiPostMoodEmployee(payload)
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'errors',
          value: ['data has been saved!']
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'alertSeverity',
          value: 'success'
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })

      return data
    } catch ({ message, response }) {
      const { data } = response
      const errorHandlerData = errorValidationHandler(data)

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'alertSeverity',
          value: 'error'
        }
      })
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'errors',
          value: errorHandlerData
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })

      return false
    }
  },
  actionDeleteEmployee: (id) => async (dispatch, getState) => {
    const state = getState()
    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'isPageLoading',
        value: true
      }
    })

    try {
      const { data } = await apiDeleteEmployee(id)

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'errors',
          value: ['data has been removed!']
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'alertSeverity',
          value: 'success'
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'employeeMetaData',
          value: {
            ...state.news.employeeMetaData,
            count: state.news.employeeMetaData.count - 1
          }
        }
      })

      return {
        error: false
      }
    } catch ({ message, response }) {
      const { data, status } = response
      const errorHandlerData = status === 403 ? [message] : errorValidationHandler(data)

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'alertSeverity',
          value: 'error'
        }
      })
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'errors',
          value: errorHandlerData
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })

      return { error: true }
    }
  },
  postNewEmployee: (payload) => async (dispatch, getState) => {
    const state = getState()
    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'isPageLoading',
        value: true
      }
    })

    try {
      const { data } = await apiPostNewEmployee(payload)
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'errors',
          value: ['data has been saved!']
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'alertSeverity',
          value: 'success'
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'employeeMetaData',
          value: {
            ...state.news.employeeMetaData,
            count: state.news.employeeMetaData.count + 1
          }
        }
      })

      return {
        error: false
      }
    } catch ({ message, response }) {
      const { data } = response
      const errorHandlerData = errorValidationHandler(data)

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'alertSeverity',
          value: 'error'
        }
      })
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'errors',
          value: errorHandlerData
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })

      return { error: true }
    }
  },
  fetchListEmployee: (payload) => async (dispatch, getState) => {
    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'isPageLoading',
        value: true
      }
    })

    // clean error messages
    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'errors',
        value: []
      }
    })

    try {

      const { data } = await apiGetEmployee(payload)
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'employeeMetaData',
          value: data
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })

    } catch (e) {
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })
    }

  },
  fetchProfile: (payload) => async (dispatch, getState) => {
    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'isPageLoading',
        value: true
      }
    })
    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'errors',
        value: []
      }
    })

    try {

      const { data } = await apiGetProfile(payload.id)

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'userProfile',
          value: data
        }
      })
      dispatch({
        type: CHANGE_ATTRIBUTE_AUTH,
        payload: {
          key: 'authenticated',
          value: data
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })

      return data

    } catch (error) {
    const { message, response } = error
    const { data, status } = response
    const errorHandlerData = status === 500 ? [message] : errorValidationHandler(data)

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'alertSeverity',
          value: 'error'
        }
      })
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'errors',
          value: errorHandlerData
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })

      return false
    }

  },
  putProfileOrganization: (payload) => async (dispatch, getState) => {
    // const state = getState()
    // const {
    //   authenticated
    // } = state
    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'isPageLoading',
        value: true
      }
    })
    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'errors',
        value: []
      }
    })

    try {
      const { data } = await apiPutProfile(payload)
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'userProfile',
          value: data
        }
      })
      dispatch({
        type: CHANGE_ATTRIBUTE_AUTH,
        payload: {
          key: 'authenticated',
          value: data
        }
      })
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'errors',
          value: ['data has been saved!']
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'alertSeverity',
          value: 'success'
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })

      return true
    } catch (error) {
      if (!error.message){
        return {
          error: true
        }
      }
      const { response } = error
      const { data } = response
      const errorHandlerData = errorValidationHandler(data)

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'alertSeverity',
          value: 'error'
        }
      })
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'errors',
          value: errorHandlerData
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })

      return false
    }
  },


}
