import moment from 'moment'
import {
  CHANGE_ATTRIBUTE as CHANGE_ATTRIBUTE_NEWS
} from 'store/reducers/news/news.constant'
import {
  CHANGE_ATTRIBUTE as CHANGE_ATTRIBUTE_AUTH
} from 'store/reducers/auth/auth.constant'
import {
  apiGetEmployeeStats,
  apiPostEmployeeStats
} from 'api/dashboard.api'
import { errorValidationHandler } from 'utils/errorHandler'
import { moods } from 'constants/mood-list'


const monthsLabel = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const actionToProps = {
  fetchEmployeeStats: (payload=false) => async (dispatch, getState) => {
    const {
      news: {
        employeeMetaData: {
          count: employeeTotalCount
        }
      }
    } = getState()

    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'isPageLoading',
        value: true
      }
    })

    // clean error messages
    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'errors',
        value: []
      }
    })

    try {

      let clearPayload = payload
      if (!payload) {
        clearPayload = {
          from_date: moment().format('YYYY-MM-DD'),
          to_date: moment(moment(), 'YYYY-MM-DD').add('days', 1).format('YYYY-MM-DD')
        }
      }

      if (payload?.total_employee === 1) {
        const { total_employee, ...clearTotalEmployee } = clearPayload
        clearPayload = clearTotalEmployee
      }

      const { data } = await apiPostEmployeeStats(clearPayload)

      let newStructuredDatas = []
      let newStructuredMonths = []
      let moodDataGraph = {}

      data.map((moodData, index)=> {
        Object.keys(moodData).map((m, idx)=> {
          const queryTotalEmployee = payload?.total_employee === 1 && payload?.u_id ? 1 : employeeTotalCount
          if (m !=='mood_date'){
            const percentageValue = Math.round(moodData[m]/ queryTotalEmployee * 100)
            if (!moodDataGraph[m]) {
              moodDataGraph[m] = [percentageValue]
            }
            else {
              moodDataGraph[m].push(percentageValue)
            }
          }
          else {
            const moodDateValue = moment(moodData[m], "YYYY-MM-DD").format("MMM Do YY")
            newStructuredMonths.push(moodDateValue)
          }

        })
        
      })
      

      Object.keys(moodDataGraph).map((val, idx)=> {
        let value = {
          label: val,
          unitSuffix: "%",
          backgroundColor: moods.find((f) => f.id === val).backgroundColor,
          data: moodDataGraph[val],
          categoryPercentage: .5,
          barPercentage: .7,
        }

        newStructuredDatas.push(value)
      })

      let graphStats = {
        labels: newStructuredMonths,
        datasets: newStructuredDatas
      }


      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'employeeEmotionStats',
          value: graphStats,
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })

    } catch (error) {
      if (!error.response){
        return {
          error: true
        }
      }
      const { response } = error
      const { data } = response
      const errorHandlerData = errorValidationHandler(data)

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'alertSeverity',
          value: 'error'
        }
      })
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'errors',
          value: errorHandlerData
        }
      })
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })

      return false
    }

  },
}
