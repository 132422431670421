import React, { useState } from 'react';
import { withRouter } from "react-router";
import { connect } from 'react-redux'
import {
    actionToProps as newsAction
} from 'store/reducers/news/news.action'
import { routerPath } from 'router/Routerlist'
import Loading from 'components/Loading'

import HeaderBarDashboard from 'components/HeaderBar/Dashboard'
import SideBarDashboard from 'components/SideBar/Dashboard'
import ModalNewEmploye from 'components/Modal/NewEmploye'
import EmployeeColumnTable from 'components/ColumnTable/Employee'
import SnackbarAlert from 'components/Alert/Snackbar'
import userIcon from "../../assets/images/icon-plus.svg"
import dashboardIcon from "../../assets/images/bulk-upload.svg"
import attachIcon from "../../assets/images/attach-icon.svg"
import downloadAttachIcon from "../../assets/images/download-attach-icon.svg"
import File from "../../components/UploadCard/File"
import templateFile from "../../assets/kindred.csv"
import { apiPostUploadBulkEmployee } from 'api/dashboard.api';
import { actionToProps } from 'store/reducers/news/actions/postUploadBulkEmployee';


const HomeView = (props) => {
    const [csvFile, csvFileUpdate] = useState(null)
    const {
      history,
      postUploadEmployee,
      news: {
        isPageLoading,
        errors,
        alertSeverity,
        // employeeMoodDetail,
        employeeMetaData: {
          // count,
          // next,
          // previous,
          results
        }
      }
    } = props

    const onUploadCsv = async (item) => {
        csvFileUpdate(item[0])
    }

    const onSubmitUpload = async () => {
        if (csvFile === null) {
            return false
        }
        let formData = new FormData()
        formData.append('csv_file', csvFile.value)
        const success = await postUploadEmployee(formData)
    }


    return (
        <>
            <div className='wrapper'>
                <HeaderBarDashboard />
                <div className="main-wrapper-content">
                    <SideBarDashboard />
                    <div className="content-dash">
                        <h2 className="mt-0 font-weight-bold">Employees Upload</h2>
                        <div className="card mb-3">
                            <div className="card-body">
                                <a href={templateFile} target="_blank" className="btn btn-primary middle mr-2 upload-color">Template download <img
                                src={downloadAttachIcon} alt="bulk" /></a>
                                <hr />
                                <div className="black-upload-title upload-page">Attach your csv file</div>
                                <File buttonName="Attach csv template"
                                    onUploadImage={onUploadCsv}
                                    singleUpload
                                    className="btn btn-primary middle mr-2 upload-color"/>
                            </div>
                        </div>

                        <div onClick={onSubmitUpload} className="btn btn-primary middle mr-2">Bulk upload <img
                            src={dashboardIcon} alt="bulk" /></div>
                    </div>
                </div>

            </div>
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        news: state.news
    }
}
const mapDispatchToProps = {
  ...actionToProps
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HomeView))
