import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { v4 as uuid } from 'uuid';
import attachIcon from "../../assets/images/attach-icon.svg"

class UploadImage extends Component {
  constructor(props) {
    super(props);
    this.chooseButtonRef = React.createRef()
    this.state = {
      imageItems: this.props.defaultValue ? this.props.defaultValue : []
    }
  }
  async onUploadImage(files) {
    const {
      imageItems
    } = this.state

    const {
      onUploadImage,
      apiUpload,
      singleUpload,
      onAlert,
      onLoader,
    } = this.props

    let newItem = {
      id: uuid(),
      url: URL.createObjectURL(files[0]),
      value: files[0]
    }

    if (apiUpload) {
      onLoader(true)
      const data = await apiUpload({
        payload: files[0]
      })

      onLoader(false)

      if (!data.response) {
        onAlert(data?.error ?? data)
        return false
      }
      if (data.response && data.response.data && data.response.data.attachment_id) {
        newItem.id = data.response.data.attachment_id
      }
    }

    let updateImageItems = imageItems

    if (singleUpload) {
      updateImageItems = [newItem]
    }

    if (!singleUpload) {
      updateImageItems.push(newItem)
    }

    this.setState({
      imageItems: updateImageItems
    })

    if (onUploadImage) {
      onUploadImage(updateImageItems)
    }
  }
  onDeleteImage(index) {
    const {
      imageItems
    } = this.state

    const {
      onDeleteImage
    } = this.props

    imageItems.splice(index, 1)

    this.setState({
      imageItems: imageItems
    })

    onDeleteImage(imageItems)
  }
  render() {
    const {
      imageItems
    } = this.state

    const {
      buttonName,
      boxType,
      defaultAvatar,
      className,
      t
    } = this.props;

    return (
      <>
        <div className={`${className}`}>
          <Dropzone ref={this.chooseButtonRef} onDrop={acceptedFiles => this.onUploadImage(acceptedFiles)}>
            {({ getRootProps, getInputProps }) => (
              <>
                <div {...getRootProps()} className="dropzonePreview button-upload">
                  <input {...getInputProps()} />
                  <div>{buttonName} <img src={attachIcon} alt="bulk" /></div>
                </div>
              </>
            )}
          </Dropzone>
        </div>
        {
          imageItems.length > 0 && (
            <div style={{marginTop: 10}}>{imageItems.length} attached</div>
          )
        }
      </>
    )
  }
}

UploadImage.defaultProps = {
  buttonName: 'default',
  inputName: 'image',
  singleUpload: false,
  apiUpload: false,
  defaultAvatar: false,
  boxType: 'box',
  className: "",
  onUploadImage: false,
  onDeleteImage: () => {
    return false
  },
  onAlert: () => {
    return false
  },
  onLoader: () => {
    return false
  },
  totalShow: 0
}

export default UploadImage
