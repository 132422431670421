// import { combineReducers } from 'redux'

import {
  reducer as AuthReducer,
  defaultState as authInitialState,
} from 'store/reducers/auth/auth.reducer'
import {
  reducer as NewsReducer,
  defaultState as newsInitialState
} from 'store/reducers/news/news.reducer'

export const reducers = {
  auth: AuthReducer,
  news: NewsReducer,
}

export const initialState = {
  auth: authInitialState,
  news: newsInitialState,
}
