import {
  CHANGE_ATTRIBUTE as CHANGE_ATTRIBUTE_AUTH
} from 'store/reducers/auth/auth.constant'
import {
  CHANGE_ATTRIBUTE as CHANGE_ATTRIBUTE_NEWS
} from 'store/reducers/news/news.constant'
import {
  apiPostSignin,
  apiPostSignup,
  apiPostTokenVerifySignup,
  apiPostResetPassword,
  apiPostTokenInput
} from 'api/login.api'
import { defaultState } from 'store/reducers/auth/auth.reducer'
import { errorValidationHandler } from 'utils/errorHandler'

export const actionToProps = {
  changeAuthAttribute: ({key, value, type=CHANGE_ATTRIBUTE_AUTH}) => dispatch => {
    return new Promise((resolve, reject)=>{
      dispatch({
        type: type,
        payload: {key: key, value: value}
      })
      resolve(value)
    })
  },
  actionPostSignIn: (payload) => async (dispatch, getState) => {
    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'isPageLoading',
        value: true
      }
    })
    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'errors',
        value: []
      }
    })

    try {

      const { data } = await apiPostSignin(payload)


      dispatch({
        type: CHANGE_ATTRIBUTE_AUTH,
        payload: {
          key: 'authenticated',
          value: data.user
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'alertSeverity',
          value: 'success'
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })


      return data
    } catch ({ message, response }) {
      const { data } = response
      const errorHandlerData = errorValidationHandler(data)

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'alertSeverity',
          value: 'error'
        }
      })
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'errors',
          value: errorHandlerData
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })

      return false
    }
  },
  actionPostSignUp: (payload) => async (dispatch, getState) => {
    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'isPageLoading',
        value: true
      }
    })
    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'errors',
        value: []
      }
    })

    try {

      const { has_read_terms, ...cleanPayload } = payload
      if (!has_read_terms) {
        throw {
          message: "Validation Error",
          response: {
            data: ["Please Read Terms and Conditions and Privacy Policy",]
          }
        }
      }

      const { data } = await apiPostSignup(cleanPayload)

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'errors',
          value: [data.message]
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'alertSeverity',
          value: 'success'
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_AUTH,
        payload: {
          key: 'authenticationForm',
          value: {
            email: {
              value: payload.email,
              page: 'signup'
            }
          }
        }
      })

      return data
    } catch ({ message, response }) {
      const { data } = response
      const errorHandlerData = errorValidationHandler(data)

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'alertSeverity',
          value: 'error'
        }
      })
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'errors',
          value: errorHandlerData
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })

      return false
    }
  },
  actionPostResetPassword: (payload) => async (dispatch, getState) => {
    // const state = getState()
    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'isPageLoading',
        value: true
      }
    })
    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'errors',
        value: []
      }
    })

    try {
      if (payload.email === '') {
        // eslint-disable-next-line
        throw {
          message: 'email is required',
          response: {
            data: {
              error: 'email is required'
            }
          }}
      }

      const { data } = await apiPostResetPassword(payload)
      if (data.message === 'User not found.'){
        // eslint-disable-next-line
        throw {
          message: data.message,
          response: {
            data: {
              error: data.message
              }
          }
          }
      }

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'errors',
          value: [data.message]
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'alertSeverity',
          value: 'success'
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_AUTH,
        payload: {
          key: 'authenticationForm',
          value: {
            email: {
              value: payload.email,
              page: 'forgot_password'
            }
          }
        }
      })

      return data
    } catch ({ message, response}) {
      const { data } = response
      const errorHandlerData = errorValidationHandler(data)

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'alertSeverity',
          value: 'error'
        }
      })
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'errors',
          value: errorHandlerData
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })

      return false
    }
  },
  actionPostTokenInput: (payload) => async (dispatch, getState) => {
    // const state = getState()
    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'isPageLoading',
        value: true
      }
    })
    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'errors',
        value: []
      }
    })

    try {

      let responseData
      if (payload.page === 'forgot_password'){
        const { confirm_password, page, ...clearPayload } = payload
        const { data } = await apiPostTokenInput(clearPayload)
        responseData = data
      }else if (payload.page === 'signup') {
        const { page, ...clearPayload } = payload
        const { data } = await apiPostTokenVerifySignup(clearPayload)
        responseData = data
      }
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'errors',
          value: [responseData.message]
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'alertSeverity',
          value: 'success'
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_AUTH,
        payload: {
          key: 'authenticationForm',
          value: defaultState.authenticationForm
        }
      })

      return true
    } catch ({ message, response }) {
      const { data } = response
      const errorHandlerData = errorValidationHandler(data)

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'alertSeverity',
          value: 'error'
        }
      })
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'errors',
          value: errorHandlerData
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })

      return false
    }
  },
}
