import axios from 'axios'
import { setupAxios } from 'utils/axiosConfig'
// import { apiToken } from 'api/self'


export const dashboardApi = {
  organizationOption: "/users/organization/",
  uploadEmployee: "/api/v1/upload-csv/",
  employeeLog: '/api/v1/daily-employee-log/',
  employee: "/api/v1/employee/",
  employeeStats: "/api/v1/employee-yearly-stats/",
  employeeMood: "/api/v1/employee-log/",
  profile: "/api/v1/profile/"
};


export const apiGetMoodEmployeeDetail = async (payload) => {
  setupAxios()
  const url = !payload ? dashboardApi.employeeLog : `${dashboardApi.employeeLog}${payload.id}/`
  return axios.get(url)
}
export const apiPostMoodEmployee = async (payload) => {
  setupAxios()
  return axios.post(dashboardApi.employeeMood, payload)
}

export const apiGetProfile = async (id) => {
  setupAxios()
  return axios.get(`${dashboardApi.profile}${id}/`)
}
export const apiPutProfile = async (payload) => {
  setupAxios()
  const { id, ...clearPayload } = payload
  return axios.put(`${dashboardApi.profile}${id}/`, clearPayload)
}
export const apiGetEmployee = async (params) => {
  setupAxios()
  return axios.get(dashboardApi.employee, { params })
}

export const apiGetOrganization = async () => {
  setupAxios()
  return axios.get(dashboardApi.organizationOption)
}

export const apiPostNewEmployee = async (payload) => {
  setupAxios()
  return axios.post(dashboardApi.employee, payload)
}

export const apiDeleteEmployee = async (id) => {
  setupAxios()
  return axios.delete(`${dashboardApi.employee}${id}/`)
}

export const apiGetEmployeeStats = async (payload) => {
  setupAxios()
  return axios.get(dashboardApi.employeeStats, payload)
}
export const apiPostEmployeeStats = async (payload) => {
  setupAxios()
  return axios.post(dashboardApi.employeeStats, payload)
}

export const apiPostUploadBulkEmployee = async (payload) => {
  setupAxios();
  return axios.post(dashboardApi.uploadEmployee, payload, {
     headers: { "Content-Type": "multipart/form-data" },
  })
}
export const getDateNow = () => {

  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;

  const date = `${yyyy}-${mm}-${dd}`
  return date
}
