import React from 'react';
import { connect } from 'react-redux'
import Loading from 'components/Loading'
import SnackbarAlert from 'components/Alert/Snackbar'
import { actionToProps as newsAction } from 'store/reducers/news/news.action'

const MainLoadPage = (props) => {
  const {
    changeNewsAttribute,
    news: {
      isPageLoading,
      alertSeverity,
      errors,
    }
  } = props

  return (

    <>
    <SnackbarAlert severity={alertSeverity}  isOpen={errors.length ? true : false} handleClose={()=> {
      changeNewsAttribute({
        key: 'errors',
        value: []
      })
    }}>
      {typeof errors === 'object' && errors.length && errors.map((err, k)=> <li key={k}>{err}</li>)}
    </SnackbarAlert>
    <Loading open={isPageLoading} />
    </>
  )
}

const mapStateToProps = (state) => {
    return {
        news: state.news,
    }
}
const mapDispatchToProps = { ...newsAction }
export default connect(mapStateToProps, mapDispatchToProps)(MainLoadPage)
