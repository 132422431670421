import { getCookie } from 'react-use-cookie';
import { environment} from 'config/environment'

export const apiToken = async (url, options = {method: 'get'}) => {
  let optionsMethod = {
    method: options.method,
    ...options,
  }

  if (options.context) {
    const tokenCookie = getCookie(environment.ACCESS_TOKEN_COOKIE)
    optionsMethod = {
      ...optionsMethod,
      headers: {
        "Authorization": `Bearer ${tokenCookie}`
      }
    }
  }

  if (options.body) {
    // let formData = new FormData();
    // Object.keys(options.body).map((e)=> {
    //   formData.append(e, options.body[e])
    // })
    optionsMethod = {
      ...optionsMethod,
      body: JSON.stringify(options.body)
    }
  }

  let responseData
  const baseURL = `${environment.BASE_API_URL}${url}`
  const response = await fetch(baseURL, optionsMethod);
  responseData = await response.json()

  return responseData
}
