import React from 'react';
import classNames from 'classnames'
import Dialog from '@mui/material/Dialog';
import DateRangePicker from 'components/RateInput/RangePicker'
import moment from 'moment'

import calendarIcon from '../../assets/images/icon-calendar.svg'
import arrowIcon from '../../assets/images/arrow-down.svg'

const formatValue = 'YYYY-MM-DD'
const formatTitle = 'LL'

const dropDownSelectInput = [{
  title: 'Today',
  caption: moment().format(formatTitle),
  value: moment().format(formatValue)
}, {
  title: 'Yesterday',
  caption: moment().subtract(1, 'days').format(formatTitle),
  value: moment().subtract(1, 'days').format(formatValue),
  to_value: moment().format(formatValue)
}, {
  title: 'Last 7 days',
  caption: moment().subtract(7, 'days').format(formatTitle),
  value: moment().subtract(7, 'days').format(formatValue),
  to_value: moment().format(formatValue)
}, {
  title: 'Last 30 days',
  caption: moment().subtract(30, 'days').format(formatTitle),
  value: moment().subtract(30, 'days').format(formatValue),
  to_value: moment().format(formatValue)
}, {
  title: 'This Month',
  caption: moment().startOf('month').format(formatTitle),
  value: moment().startOf('month').format(formatValue),
  to_value: moment().format(formatValue)
}, {
  title: 'Last Month',
  caption: moment().subtract(1, 'months').startOf('month').format(formatTitle),
  value: moment().subtract(1, 'months').startOf('month').format(formatValue),
  to_value: moment().subtract(1, 'months').endOf('month').format(formatValue)
}, {
  title: 'Custom Range',
}]

const RateInputDateRange = (props) => {
  const [openDropdown, setOpenDropdown] = React.useState(false)
  const [openPicker, setOpenPicker] = React.useState(false)
  const [value, setValue] = React.useState('Today')
  const {
    onConfirm,
  } = props

  const handleGetRangeValue = (v) => {
    const from = moment(v[0]).format(formatTitle)
    const to = moment(v[1]).format(formatTitle)

    const fromValue = moment(v[0]).format(formatValue)
    const toValue = moment(v[1]).format(formatValue)

    setValue(`${from} - ${to}`)
    setOpenDropdown(false)
    setOpenPicker(false)
    onConfirm({ from: fromValue, to: toValue })
  }

  const handleSetValue = (v) => {
    setValue(v.title)
    setOpenDropdown(false)
    setOpenPicker(false)
    let payload = { from: v.value, to: v.value }
    if (v.title !== 'Today') {
      payload = {
        ...payload,
        to: v.to_value
      }
    }
    onConfirm(payload)
  }

  return (
      <>
                <div className='dropdown'>
                    <div className='btn' onClick={() => setOpenDropdown(!openDropdown)}>
                        <span>
                            <img src={calendarIcon} alt="calendar" />
                        </span>
                        {value}
                        <span className='right-icon'>
                            <img src={arrowIcon} alt="right-icon"/>
                        </span>
                    </div>
                    <div className={classNames({
                      'dropdown-menu zIndex999': true,
                      'hide': !openDropdown
                    })}>
                      {
                        dropDownSelectInput.map((d, idx)=> {
                          let handleClick = () => {
                            handleSetValue(d)
                          }
                          if (idx === dropDownSelectInput.length-1){
                            handleClick = () => {
                              setOpenPicker(true)
                            }
                          }
                          return (
                            <div className='dropdown-item' onClick={handleClick} key={idx}>{d.title}</div>
                          )
                        })
                      }
                    </div>
                </div>

          <Dialog
            fullWidth={false}
            maxWidth={'md'}
            open={openPicker}
            onClose={()=> setOpenPicker(false)}
          >
            <DateRangePicker getValue={handleGetRangeValue}/>
        </Dialog>
      </>
  )
}

RateInputDateRange.defaultProps = {
  // date: '02/16/2022'
  onConfirm: () => false,
}

export default RateInputDateRange;
