import React, { useState, useRef } from 'react';

import { withRouter } from "react-router";
import KindredLogoAuth from 'components/KindredLogo/Auth'
import TokenInput from 'components/Token/email-otp'
import { connect } from 'react-redux'
import {
    actionToProps as authAction
} from 'store/reducers/auth/auth.action'
import {
    actionToProps as newsAction
} from 'store/reducers/news/news.action'
import { routerPath } from 'router/Routerlist'
import Loading from 'components/Loading'
import SnackbarAlert from 'components/Alert/Snackbar'
import mailIcon from "../../assets/images/icon-mail.svg"
import lockIcon from "../../assets/images/icon-lock.svg"

const TokenView = (props) => {
    const {
        history,
        actionPostTokenInput,
        changeNewsAttribute,
        news: {
          alertSeverity,
          errors,
          isPageLoading
        },
        auth: {
          authenticationForm
        }
    } = props
    const formInput = useRef(null)
    const [otp, setOtp] = useState('')

    const handleSubmit = async (e) => {
      e.preventDefault()

      let payload = {
        email: authenticationForm.email.value,
        token: otp,
        page: authenticationForm.email.page,
      }

      if(payload.page === 'forgot_password'){
        payload = {
          ...payload,
          password: formInput.current.querySelector('input#password').value,
          confirm_password: formInput.current.querySelector('input#confirm_password').value,
        }
      }
      const success = await actionPostTokenInput(payload);
      if (success){
        setTimeout(()=> {
          history.push(routerPath.auth.signin)
        },300)
      }
    }

    return (
        <>
            <div className='wrapper auth-bg'>
                <div className="sm-container">
                    <div className="sm-container-inner">
                        <KindredLogoAuth />
                        <div className="sm-container-content">

                            <form ref={formInput} noValidate onSubmit={handleSubmit} className="form-input">
                            <div className="form-content">
                                <div className='text-center mb-2'>
                                    <h3 className="mt-0">Enter Token</h3>
                                </div>
                                <TokenInput otpLength={4} onSendOtpValue={(val) => setOtp(val)}/>
                                <div className="mb-2 hide">
                                    <label>Email</label>
                                    <div className="form-inline-group">
                                        <span>
                                            <img src={mailIcon} alt="" />
                                        </span>
                                        <input type="text" className="form-control" placeholder="Enter your email" />
                                    </div>
                                </div>
                                {
                                  authenticationForm.email.page === 'forgot_password' && (
                                    <>
                                    <div className="mb-2">
                                        <label>Set New Password</label>
                                        <div className="form-inline-group">
                                            <span>
                                                <img src={lockIcon} alt="" />
                                            </span>
                                            <input type="password" className="form-control" placeholder="Enter password" id="password"/>
                                        </div>
                                    </div>
                                    <div className="mb-2">
                                        <label>Confirm New Password</label>
                                        <div className="form-inline-group">
                                            <span>
                                                <img src={lockIcon} alt="" />
                                            </span>
                                            <input type="password" className="form-control" placeholder="Enter password" id="confirm_password"/>
                                        </div>
                                    </div>
                                    </>
                                  )
                                }

                                <button className="btn btn-primary btn-block mb-1" type="submit">Submit</button>
                                <button className="btn btn-outline-primary btn-block" onClick={()=> {
                                  history.push(routerPath.auth.signin)
                                }}>Cancel</button>

                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <SnackbarAlert severity={alertSeverity}  isOpen={errors.length ? true : false} handleClose={()=> {
              changeNewsAttribute({
                key: 'errors',
                value: []
              })
            }}>
              {typeof errors === 'object' && errors.length && errors.map((err, k)=> <li key={k}>{err}</li>)}
            </SnackbarAlert>
          <Loading open={isPageLoading} />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        news: state.news,
        auth: state.auth,
    }
}
const mapDispatchToProps = { ...authAction, ...newsAction }
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TokenView))
