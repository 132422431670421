import React from 'react';
import kindredIcon from "../../assets/images/kindred_logo.svg"
import searchIcon from "../../assets/images/icon-search.svg"
import arrowIcon from "../../assets/images/icon-arrow.svg"
export default (props) => {
  return (

    <div className="header-bar">
        <div className="header-right-bar cursor-pointer">
            <div>
                <img src={kindredIcon} className="img-fluid" alt="" />
            </div>
            <div className="w-100 pl-3 pr-3">
                <div className="form-inline-group">
                    <span>
                        <img src={searchIcon} alt="" />
                    </span>
                    <input type="text" className="form-control" />
                </div>
            </div>
            <div>
                <div className="btn-arrow">
                    <img src={arrowIcon} className="img-fluid" alt="" />
                </div>
            </div>
        </div>
    </div>
  )
}
