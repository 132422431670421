import moment from 'moment'
import {
  CHANGE_ATTRIBUTE as CHANGE_ATTRIBUTE_NEWS
} from 'store/reducers/news/news.constant'
import {
  CHANGE_ATTRIBUTE as CHANGE_ATTRIBUTE_AUTH
} from 'store/reducers/auth/auth.constant'
import {
  apiPostUploadBulkEmployee
} from 'api/dashboard.api'
import { errorValidationHandler } from 'utils/errorHandler'

export const actionToProps = {
  postUploadEmployee: (payload=false) => async (dispatch, getState) => {

    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'isPageLoading',
        value: true
      }
    })

    // clean error messages
    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'errors',
        value: []
      }
    })

    try {
      const success = await apiPostUploadBulkEmployee(payload)

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'alertSeverity',
          value: 'success'
        }
      })
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'errors',
          value: ['Bulk Upload Employee success!']
        }
      })

    } catch (error) {
      if (!error.response){
        return {
          error: true
        }
      }
      const { response, message } = error
      const { data } = response
      const errorHandlerData = !data ? [message] : errorValidationHandler(data)
      
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'alertSeverity',
          value: 'error'
        }
      })
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'errors',
          value: errorHandlerData
        }
      })
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })

      return false
    }

  },
}
