import React from 'react';
import classNames from 'classnames'
import arrowUpIcon from '../../assets/images/icon-arrow-up.svg'
import arrowDownIcon from '../../assets/images/icon-arrow-down.svg'
import trashIcon from "../../assets/images/icon-trash.svg"

const EmployeeColumn = (props) => {
  const {
    name,
    // added_by,
    // date,
    email,
    is_joined,
    onRemoveEmployee,
    onGetDetailEmployee,
    id,
  } = props

  return (
    <div className="table-col cursor-pointer">
        <div className="table-content">
            <div className="name" onClick={()=> onGetDetailEmployee({id, name, email})}>{name}</div>
            <div className="email">{email}</div>
            <div className="label">
                <div  className={classNames({
                  'joined': is_joined,
                  'unjoined': !is_joined,
                  'label-j': true,
                })}>
                    <img src={is_joined ? arrowUpIcon : arrowDownIcon} alt={name} /> {is_joined ? 'Joined' : 'Not Joined'}
                </div>
            </div>

            <div className="del-btn" onClick={()=> {
              onRemoveEmployee(id)
            }}>
                <div className="link">
                    <img src={trashIcon} alt="" />
                </div>
            </div>
        </div>
    </div>
  )
}

EmployeeColumn.defaultProps = {
  onGetDetailEmployee: () => false,
  onRemoveEmployee: () => false
}

export default EmployeeColumn
