import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    // Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend
);


export const options = {
    scales: {
      x: {
        grid: {
          display: false
        }
      },
    },
    // responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 2,
    plugins: {
        title: {
            display: false,
        },
    },

};

const labels = ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Okt', 'Nov', 'Des'];

export default (props) => {
  const {
    // open,
    // data,
    datasets,
  } = props

  return (

    <div className='chart-wrapper'>
        <div class="chart-container">
            <Bar options={options} data={{
              labels,
              datasets,
            }} />
        </div>
    </div>
  )
}
