import React from 'react';
import { withRouter } from "react-router";
import { connect } from 'react-redux'
import {
    actionToProps as authAction
} from 'store/reducers/auth/auth.action'
import {
    actionToProps as newsAction
} from 'store/reducers/news/news.action'
import { routerPath } from 'router/Routerlist'

import Loading from 'components/Loading'
import SnackbarAlert from 'components/Alert/Snackbar'
import KindredLogoAuth from 'components/KindredLogo/Auth'
import mailIcon from "../../assets/images/icon-mail.svg"

const ForgotpasswordView = (props) => {
    const formInput = React.useRef(null)
    const {
        history,
        news: {
          isPageLoading,
          errors,
          alertSeverity,
        },
        actionPostResetPassword,
        changeNewsAttribute,
        // changeAuthAttribute
    } = props

    const handleSubmit = async (e) => {
      e.preventDefault()

      const payload = {
        email: formInput.current.querySelector('input#email').value,
      }

      const success = await actionPostResetPassword(payload)
      if (success){
        setTimeout(()=> {
          history.push(routerPath.auth.token)
        },300)
      }

    }
    return (
        <>
            <div className='wrapper auth-bg'>
                <div className="sm-container">
                    <div className="sm-container-inner">
                        <KindredLogoAuth />
                        <form ref={formInput} noValidate onSubmit={handleSubmit} className="form-input">
                        <div className="sm-container-content">
                            <div className="form-content">
                                <div className="mb-2">
                                    <label>Email</label>
                                    <div className="form-inline-group">
                                        <span>
                                            <img src={mailIcon} alt="" />
                                        </span>
                                        <input type="text" className="form-control" placeholder="Enter your email" id="email"/>
                                    </div>
                                </div>

                                <button className="btn btn-primary btn-block" type="submit">Reset Password</button>

                            </div>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
            <SnackbarAlert severity={alertSeverity}  isOpen={errors.length ? true : false} handleClose={()=> changeNewsAttribute({
              key: 'errors',
              value: []
            })}>
              {typeof errors === 'object' && errors.length && errors.map((err, k)=> <li key={k}>{err}</li>)}
            </SnackbarAlert>
            <Loading open={isPageLoading} />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        news: state.news,
    }
}
const mapDispatchToProps = { ...authAction, ...newsAction }
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ForgotpasswordView))
