import React from 'react';
import { withRouter } from "react-router";
import { connect } from 'react-redux'
import moment from 'moment'

import {
    actionToProps as newsAction
} from 'store/reducers/news/news.action'
import {
  routerPath
} from 'router/Routerlist'

import HeaderBarDashboard from 'components/HeaderBar/Dashboard'
import SideBarDashboard from 'components/SideBar/Dashboard'
import RateInputDate from 'components/RateInput/Date'
import RateInputMood from 'components/RateInput/Mood'
import Loading from 'components/Loading'
import SnackbarAlert from 'components/Alert/Snackbar'


const RateView = (props) => {
  const {
    history,
    actionPostMood,
    changeNewsAttribute,
    auth: {
      authenticated
    },
    news: {
      alertSeverity,
      errors,
      isPageLoading
    }
  } = props

  const redirectLink = authenticated && authenticated.is_admin ? routerPath.user.dashboard : routerPath.user.rate;

  const onSelect = async (mood) => {

    const payload = {
      mood: mood.title,
    }
    const success = await actionPostMood(payload);
    if (success){
      history.push(redirectLink)
    }
  }
  return (
  <>
      <div className='wrapper'>
          <HeaderBarDashboard />
          <div className="main-wrapper-content">
              <SideBarDashboard />
              <div className="content-dash">
                  <div className="card">
                      <div className="card-body">
                          <div className="mt-5 mb-5">
                              <RateInputDate date={moment().format('L')}/>
                              <RateInputMood onSelect={onSelect}/>
                          </div>
                      </div>
                      <div className="card-body greeting-card">
                        <div className="container">
                          <h2>Greetings!</h2>
                          <div className="container-item">

                            <div className="items">
                              <ul>
                                <li>Reminder there is no right or wrong answer</li>
                                <li>your selection will not impact your employmment</li>
                              </ul>
                            </div>

                            <div className="items">
                              <ul>
                                <li>This survey intends to open up a space to check in with yourself</li>
                                <li>This also allows your employer to monitor the happiness of your company</li>
                              </ul>
                            </div>

                            <div className="items">
                              <ul>
                                <li>If the overall happiness levels are down, then they will take action to get happiness levels back up</li>
                                <li>Thanks and have a marvelous day!</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
          </div>

      </div>
      <SnackbarAlert severity={alertSeverity}  isOpen={errors.length ? true : false} handleClose={()=> {
          changeNewsAttribute({
            key: 'errors',
            value: []
          })
        }}>
          {errors.length && errors.map((err, k)=> <li key={k}>{err}</li>)}
        </SnackbarAlert>
      <Loading open={isPageLoading} />
  </>
  );
}

const mapStateToProps = (state) => {
    return {
        news: state.news,
        auth: state.auth,
    }
}
const mapDispatchToProps = {
  ...newsAction
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RateView))
