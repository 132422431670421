import React, { useState } from 'react';
import { withRouter } from "react-router";
import { connect } from 'react-redux'
import moment from 'moment'

import {
    actionToProps as newsAction
} from 'store/reducers/news/news.action'

import HeaderBarDashboard from 'components/HeaderBar/Dashboard'
import SideBarDashboard from 'components/SideBar/Dashboard'
import Loading from 'components/Loading'
import SnackbarAlert from 'components/Alert/Snackbar'
import RateInputDateRange from 'components/RateInput/DateRange'
import RateInputDate from 'components/RateInput/Date'
import { moods } from 'constants/mood-list'

const AdminrateView = (props) => {
  const {
    // history,
    actionGetMoodEmployeeDetail,
    changeNewsAttribute,
    auth: {
      authenticated
    },
    news: {
      isPageLoading,
      alertSeverity,
      errors,
      employeeMoodDetail,
    }
  } = props
  const [moodData, setMoodData] = useState(employeeMoodDetail)

  React.useEffect(()=> {
    if (!authenticated.is_admin){
      // handleGetDetailMoodEmployee(authenticated.id)
      actionGetMoodEmployeeDetail()
    }
  // eslint-disable-next-line
  }, [actionGetMoodEmployeeDetail])

  React.useEffect(()=> {
    setMoodData(employeeMoodDetail)
  }, [employeeMoodDetail])

  // const handleGetDetailMoodEmployee = async (id) => {
  //   console.log({
  //     moodData
  //   });
  //   const success = await actionGetMoodEmployeeDetail({ id })
  //   return success
  // }


  const handleFetchFilterDateStats = async (date) => {
    let clearPayload = {
      date: date.from,
      id: authenticated.id
    }
    if (date.to){
      clearPayload = {
        ...clearPayload,
        from_date: date.from,
        to_date: date.to,
      }
    }
    const success = await actionGetMoodEmployeeDetail(clearPayload)
    return success
  }

return (
<>
    <div className='wrapper'>
        <HeaderBarDashboard />
        <div className="main-wrapper-content">
            <SideBarDashboard />
            <div className="content-dash">
                <h2 className="mt-0 font-weight-bold hide">List of employees</h2>
                <div className="card mb-3">
                    <div className="card-header">
                        <div className="mui-row d-flex flex-wrap items-center">
                            <div className="mui-col-md-7">
                                <h3 className="mb-0 mt-0">Daily log:</h3>
                            </div>
                            {
                              authenticated?.is_admin && (
                                <div className="mui-col-md-5 text-right hide">
                                  <RateInputDateRange onConfirm={handleFetchFilterDateStats} />
                                </div>
                              )
                            }
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="statistic text-center">
                          {
                            authenticated?.is_admin && (
                              <>
                              <h2>{moodData?.name || 'Unnamed'}</h2>
                              <p>{moodData?.email}</p>
                              </>
                            )
                          }
                          {
                            !authenticated?.is_admin && (
                              <RateInputDate date={moment(moodData.mood_date).format('L')}/>
                            )
                          }

                            <div className="mt-4 pb-5">
                              {
                                moods.map(e=> e.title).includes(moodData.mood) && (
                                  <img src={moods.find(e => e.title === moodData.mood)?.image || "/images/happy.svg"} alt=""/>
                                )
                              }
                              {
                                !moods.map(e=> e.title).includes(moodData.mood) && (
                                  <div className="no-data">{moodData.message}</div>
                                )
                              }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SnackbarAlert severity={alertSeverity}  isOpen={errors.length ? true : false} handleClose={()=> changeNewsAttribute({
          key: 'errors',
          value: []
        })}>
          {errors.length && errors.map((err, k)=> <li key={k}>{err}</li>)}
        </SnackbarAlert>
        <Loading open={isPageLoading} />

    </div>
</>
);
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        news: state.news,
    }
}
const mapDispatchToProps = { ...newsAction }
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminrateView))
