import React from 'react';
import classNames from 'classnames'
import arrowIcon from '../../assets/images/arrow-down.svg'
import { apiGetOrganization } from 'api/dashboard.api';
import { connect } from 'react-redux';
import searchIcon from "../../assets/images/icon-search.svg"

const OrganizationDropdownList = (props) => {

  const {
    onConfirm,
    defaultTitle,
    containerStyle,
    inputStyle,
    dropdownStyle,
    isDisplayAll,
    auth: {
      authenticated
    }
  } = props

  const [openDropdown, setOpenDropdown] = React.useState(false)
  const [openPicker, setOpenPicker] = React.useState(false)
  const [value, setValue] = React.useState(defaultTitle)
  const [dropDownSelectInput, dropDownSelectInputUpdate] = React.useState([])
  const [searchText, setSearchText] = React.useState('')

  React.useEffect(()=> {
    getOrganizationList()
  }, [])

  const getOrganizationList = async () => {
    const { data } = await apiGetOrganization()
    let selectOptions = data.results

    if (authenticated?.is_organization_admin && authenticated?.organization) {
      selectOptions = data.results.filter(e => e.id === authenticated?.organization?.id)
    }

    if (authenticated?.organization && (!authenticated?.is_organization_admin && !authenticated?.is_admin)) {
      selectOptions = data.results.filter(e => e.id === authenticated?.organization?.id)
    }

    if (isDisplayAll && authenticated?.is_admin) selectOptions.push({
      id: null,
      name: 'All Organization'
    })

    dropDownSelectInputUpdate(selectOptions)
  }

  const handleSetValue = (v) => {
    setValue(v.name)
    setOpenDropdown(false)
    setOpenPicker(false)
    onConfirm(v.id)
  }

  return (
      <>
          <div className='dropdown organization-menu' style={containerStyle}>
              <div className='btn' onClick={() => setOpenDropdown(!openDropdown)} style={inputStyle}>
                  {value}
                  <span className='right-icon'>
                      <img src={arrowIcon} alt="right-icon"/>
                  </span>
              </div>
              <div className={classNames({
                'dropdown-menu zIndex999': true,
                'hide': !openDropdown
              })} style={dropdownStyle}>
                
                <div className="form-inline-group" style={{padding: '10px'}}>
                    <span style={{left: '10px'}}>
                        <img src={searchIcon} alt=""/>
                    </span>
                    <input type="text" className="form-control" onChange={(e)=> {
                      let string = new RegExp(e.target.value, 'i')
                      setSearchText(string)
                    }}/>
                </div>
                {
                  dropDownSelectInput.filter(e => e ? e.name.match(searchText) : true ).map((d, idx)=> {
                    let handleClick = () => {
                      handleSetValue(d)
                    }
                    return (
                      <div className='dropdown-item' onClick={handleClick} key={idx}>{d.name}</div>
                    )
                  })
                }
              </div>
          </div>

      </>
  )
}

OrganizationDropdownList.defaultProps = {
  onConfirm: () => false,
  defaultTitle: 'Select Organization',
  isDisplayAll: false,
  containerStyle: {},
  inputStyle: {},
  dropdownStyle: {}
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationDropdownList)
