import * as React from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const selectionInit = [
  {
    startDate: new Date(),
    endDate: null,
    key: 'selection'
  }
]

export default function StaticDateRangePickerDemo(props) {
  const [selection, setSelection] = React.useState(selectionInit);

  const {
    getValue
  } = props
  const handleSelect = (item) => {
    setSelection([item.selection])
  }

  return (
    <div className="range-picker-container">
      <DateRange
        ranges={selection}
        months={2}
        direction="horizontal"
        onChange={handleSelect}
      />
      <div className="btn btn-primary"
        onClick={()=> {
          getValue([
            selection[0].startDate,
            selection[0].endDate
          ])
        }}
        style={{display: 'inherit', textAlign: 'center'}}>Select</div>
    </div>

  );
}
