import React, { useState } from 'react';
import { withRouter } from "react-router";
import { connect } from 'react-redux'
import {
    actionToProps as newsAction
} from 'store/reducers/news/news.action'
import { routerPath } from 'router/Routerlist'
import Loading from 'components/Loading'

import HeaderBarDashboard from 'components/HeaderBar/Dashboard'
import SideBarDashboard from 'components/SideBar/Dashboard'
import ModalNewEmploye from 'components/Modal/NewEmploye'
import EmployeeColumnTable from 'components/ColumnTable/Employee'
import SnackbarAlert from 'components/Alert/Snackbar'
import userIcon from "../../assets/images/icon-plus.svg"
import dashboardIcon from "../../assets/images/icon-dashboard.svg"
import OrganizationDropdownList from 'components/Organization/OptionList';


const HomeView = (props) => {
    const [modalNewEmploye, setModalNewEmploye] = useState(false)
    const {
      history,
      fetchListEmployee,
      postNewEmployee,
      changeNewsAttribute,
      actionDeleteEmployee,
      actionGetMoodEmployeeDetail,
      news: {
        isPageLoading,
        errors,
        alertSeverity,
        // employeeMoodDetail,
        employeeMetaData: {
          // count,
          // next,
          // previous,
          results
        }
      }
    } = props

    React.useEffect(()=> {
      fetchListEmployee()
    }, [fetchListEmployee])

    const handleSubmit = async (payload) => {
      const { error } = await postNewEmployee(payload);
      if (!error){
        setModalNewEmploye(false)
        fetchListEmployee()
      }
    }

    const handleRemoveEmployee = async (id) => {
      const { error } = await actionDeleteEmployee(id)
      if (!error){
        fetchListEmployee()
      }
    }

    const handleGetDetailEmployee = async ({id, name, email}) => {
      const success = await actionGetMoodEmployeeDetail({ id, name, email })
      if (success) {
        history.push(routerPath.user.moodDetail)
      }
    }

    const handleFilterEmployeeByOrg = async (id) => {
      fetchListEmployee(id ? { organization_id: id} : null)
    }

    return (
        <>
            <div className='wrapper'>
                <HeaderBarDashboard />
                <div className="main-wrapper-content">
                    <SideBarDashboard />
                    <div className="content-dash">
                        
                        <div className="mui-row flex-wrap items-center" style={{marginleft: 0, marginBottom: 10}}>
                          <div className="mui-col-md-5 text-right">
                            <h2 className="mt-0 font-weight-bold">List of employees</h2>
                          </div>
                          <div className="mui-col-md-4 text-right" style={{float: 'right'}}>
                            <OrganizationDropdownList isDisplayAll defaultTitle={"Sort by organization"} onConfirm={handleFilterEmployeeByOrg}/>
                          </div>
                        </div>
                        <div className="card mb-3">
                            <div className="card-body">
                                <div className="table-row">
                                    {
                                      results && results?.map((r, idx)=> {
                                        const employeeColumnProps = {
                                          ...r,
                                          onGetDetailEmployee: handleGetDetailEmployee,
                                          onRemoveEmployee: handleRemoveEmployee
                                        }
                                        return (
                                          <EmployeeColumnTable {...employeeColumnProps} key={idx}/>
                                        )
                                      })
                                    }
                                    {
                                      results.length === 0 && (<div style={{textAlign: 'center', width: '100%'}}>Data not found!</div>)
                                    }

                                </div>
                            </div>
                        </div>

                        <div onClick={()=> setModalNewEmploye(true)} className="btn btn-primary middle mr-2">Add new emplyoee <img src={userIcon}
                            alt="" /></div>
                        <div className="btn btn-outline-primary-light middle" onClick={()=> history.push(routerPath.user.upload)}>Bulk upload emploee <img
                            src={dashboardIcon} alt="bulk" /></div>
                    </div>
                </div>

                <SnackbarAlert severity={alertSeverity}  isOpen={errors.length ? true : false} handleClose={()=> {
                  changeNewsAttribute({
                    key: 'errors',
                    value: []
                  })
                }}>
                  {errors.length && errors.map((err, k)=> <li key={k}>{err}</li>)}
                </SnackbarAlert>
                <ModalNewEmploye
                  handleConfirm={handleSubmit}
                  open={modalNewEmploye}
                  handleClose={()=> setModalNewEmploye(!modalNewEmploye)}/>
                <Loading open={isPageLoading} />

            </div>
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        news: state.news
    }
}
const mapDispatchToProps = {
  ...newsAction
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HomeView))
