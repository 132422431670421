import * as constants from './news.constant'

export const defaultState = {
  categoryItems: [],
  items: [],
  detail: null,
  purchaseItems: [],
  didMountHomepage: false,
  didMount: false,
  isPageLoading: false,
  alertSeverity: 'error',
  errors: [],
  userProfile: {
    name: '',
    organization: '',
    email: '',
  },
  employeeMoodDetail: {
    id: '',
    name: '',
    email: '',
    message: '',
    date: '',
    mood: ''
  },
  employeeEmotionStats: [],
  employeeMetaData: {
    count: 0,
    next: null,
    previous: null,
    results: []
  }
}
const ACTION_HANDLERS = {
  [constants.LOGOUT]: (state, action) => {
    return { ...state, ...defaultState }
  },
  [constants.CHANGE_ATTRIBUTE]: (state, action) => {
    const { value, key } = action.payload;

    const updateState = {
      ...state,
      [key]: value
    }

    return Object.assign({}, state, updateState)
  },
  [constants.INIT]: (state, action) => {
    return { ...state, ...action.payload }
  },
  [constants.HYDRATE]: (state, action) => {
    return { ...state, ...action.payload.news }
  }
}


export const reducer =
  (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
