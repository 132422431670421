import React from 'react';
import { withRouter } from "react-router";
import { connect } from 'react-redux'

import HeaderBarDashboard from 'components/HeaderBar/Dashboard'
import SideBarDashboard from 'components/SideBar/Dashboard'
import Loading from 'components/Loading'
import BarChart from 'components/Statistic/BarChart'
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormHelperText from '@mui/material/FormHelperText';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
import {
    actionToProps as newsAction
} from 'store/reducers/news/news.action'
// import RateInputDateRange from 'components/RateInput/DateRange'
import SnackbarAlert from 'components/Alert/Snackbar'



const StatisticsView = (props) => {
    // const [modalNewEmploye, setModalNewEmploye] = useState(false)
    const {
        // history,
        fetchEmployeeStats,
        changeNewsAttribute,
        news: {
            employeeEmotionStats,
            errors,
            alertSeverity,
            isPageLoading
        }
    } = props

    React.useEffect(() => {
        fetchEmployeeStats()
    }, [fetchEmployeeStats])

    // const handleFetchFilterDateStats = (date) => {
    //     let clearDate = {
    //         date: date.from
    //     }
    //     if (date.to) {
    //         clearDate = {
    //             to_date: date.to,
    //             from_date: date.from,
    //         }
    //     }
    //     fetchEmployeeStats(clearDate)
    // }
    return (
        <>
            <div className='wrapper'>
                <HeaderBarDashboard />
                <div className="main-wrapper-content">
                    <SideBarDashboard />
                    <div className="content-dash">
                        <h2 className="mt-0 font-weight-bold">Statistic</h2>
                        <div className="card mb-3">
                            <div className="card-body">
                                <div className="statistic">
                                    <h2 className="mb-5">Overall happines score</h2>
                                    <p className='mui--text-center text-grey'>Mood Statistics</p>
                                    <div class="chartWrapper">
                                        <div class="chartAreaWrapper">
                                            <div class="chartAreaWrapper2">
                                            </div>
                                        </div>
                                    </div>

                                    <BarChart datasets={employeeEmotionStats}/>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <SnackbarAlert severity={alertSeverity} isOpen={errors.length ? true : false} handleClose={() => changeNewsAttribute({
                key: 'errors',
                value: []
            })}>
                {errors.length && errors.map((err, k) => <li key={k}>{err}</li>)}
            </SnackbarAlert>

            <Loading open={isPageLoading} />
        </>
    );
}


const mapStateToProps = (state) => {
    return {
        news: state.news
    }
}
const mapDispatchToProps = {
    ...newsAction
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StatisticsView))
