export const errorValidationHandler = (error) => {
  const data = error
  const displayMessage = []
  const errorMessage = []
  Object.keys(data).map((d)=> {
    let messageData = d !== "0" && data[d][d] ? data[d][d] : data[d]
    if (typeof messageData === 'object' && messageData?.['non_field_errors'] ) {
      messageData = messageData?.['non_field_errors']?.['0']
    }
    displayMessage.push(
      `${d}: ${messageData}`
    )
    return messageData
  })

  return errorMessage.concat(displayMessage)
}
