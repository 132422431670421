import React from 'react';
import { withRouter } from "react-router";

import { routerPath } from 'router/Routerlist'
import logo from '../../assets/images/kindred_logo.svg'
const KindredLogo = (props) => {
  const {
    page,
    redirectLink,
    history
  } = props
  return (
    <div className="mui--text-center mb-2 cursor-pointer" onClick={()=> redirectLink ? redirectLink() : history.push(page !== 'profile' ? routerPath.homepage.root : routerPath.user.dashboard)}>
        <img src={logo} className="img-fluid" alt="" />
    </div>
  )
}

KindredLogo.defaultProps = {
  redirectLink: false,
  page: 'login'
}
export default withRouter(KindredLogo)
