import React from 'react';
import PropTypes from "prop-types";
// import OtpInput from 'react-otp-input';
import OTPInput from 'otp-input-react'

const ReuseComponent = (props) => {
  const [otp, setOtpState] = React.useState('')

  const {
    otpLength,
    onSendOtpValue,
    // onResendCode,
    // resendCode,
  } = props

  return (

    <div className="mui-row mb-2 token-input">
    <OTPInput value={otp} onChange={val => {
      setOtpState(val)
      if (val.length === otpLength){
        onSendOtpValue(val)
      }
    }} autoFocus
      OTPLength={otpLength}
      inputClassName="form-control"
      className="token-container"
      otpType="number" disabled={false} />
    </div>
  );
}
ReuseComponent.defaultProps = {
  otpLength: 6,
  onSendOtpValue: (e)=>{
    return e
  },
  // onResendCode: ()=>{
  //   return false
  // },
  // resendCode: false,
}
ReuseComponent.propTypes = {
  otpLength: PropTypes.any,
  onSendOtpValue: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
}
export default ReuseComponent
