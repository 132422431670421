import React from 'react';

const RateInputDate = (props) => {
  const {
    date
  } = props

  return (
    <div className="text-center mb-5">
        <div className="daterate">
            <b>Date:</b> {date}
        </div>
    </div>
  )
}

RateInputDate.defaultProps = {
  date: '02/16/2022'
}

export default RateInputDate;
