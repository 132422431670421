import React from "react";
// import { Router, Route, Switch } from "react-router-dom";
import { Router, Route, Switch } from "react-router";
import { createBrowserHistory } from "history";

// import { Router } from '@reach/router';
import { routerPath } from "./Routerlist";

import Signin from 'views/Auth/signin'
import Signup from 'views/Auth/signup'
import Forgotpassword from 'views/Auth/forgot_password'
import Profile from 'views/User/profile'
import Token from 'views/Auth/token'
import Home from 'views/User/home'
import Statistics from 'views/User/statistics'
import AdminStatistics from 'views/User/statistics-admin'
import Adminrate from 'views/User/adminrate'
import MoodDetail from 'views/User/mood-detail'
import Rate from 'views/User/rate'
import UploadEmployee from 'views/User/upload'


var browserHistory = createBrowserHistory();
const RouterPath = () => {

  return (
    <Router history={browserHistory}>
      <Switch>
        <Route
          path={routerPath.homepage.root}
          component={Signin}
          default
          exact
        />
        <Route
          path={routerPath.auth.signin}
          component={Signin}
          exact
        />
        <Route
          path={routerPath.auth.signup}
          component={Signup}
          exact
        />
        <Route
          path={routerPath.auth.forgot_password}
          component={Forgotpassword}
          exact
        />
        <Route
          path={routerPath.auth.token}
          component={Token}
          exact
        />
        <Route
          path={routerPath.user.profile}
          component={Profile}
          exact
        />
        <Route
          path={routerPath.user.dashboard}
          component={Home}
          exact
        />
        <Route
          path={routerPath.user.statistics}
          component={Statistics}
          exact
        />
        <Route
          path={routerPath.user.adminstatistics}
          component={AdminStatistics}
          exact
        />
        <Route
          path={routerPath.user.adminrate}
          component={Adminrate}
          exact
        />
        <Route
          path={routerPath.user.rate}
          component={Rate}
          exact
        />
        <Route
          path={routerPath.user.upload}
          component={UploadEmployee}
          exact
        />
        <Route
          path={routerPath.user.moodDetail}
          component={MoodDetail}
          exact
        />
      </Switch>
    </Router>
  )
}

export default RouterPath
