import React from 'react';
import { withRouter } from "react-router";

import HeaderBarDashboard from 'components/HeaderBar/Dashboard'
import SideBarDashboard from 'components/SideBar/Dashboard'


const AdminrateView = (props) => {
  
return (
<>
    <div className='wrapper'>
        <HeaderBarDashboard />
        <div className="main-wrapper-content">
            <SideBarDashboard />
            <div className="content-dash">
                <h2 className="mt-0 font-weight-bold">List of employees</h2>
                <div className="card mb-3">
                    <div className="card-header">
                        <div className="mui-row d-flex flex-wrap items-center">
                            <div className="mui-col-md-8">
                                <h3 className="mb-0 mt-0">Daily log:</h3>
                            </div>
                            <div className="mui-col-md-4 text-right">
                                <div className="form-inline-group">
                                    <span>
                                        <img src="/images/icon-calendar.svg" alt="" />
                                    </span>
                                    <input id="text" type="text" className="form-control" />
                                    <span className="right-icon"><img src="/images/icon-chevron-down.svg" alt="" /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="statistic text-center">
                            <h2>Richard Hart</h2>
                            <p>richardhart@companyemail.com</p>

                            <div className="mt-4 pb-5">
                                <img src="/images/happy.svg" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</>
);
}
export default withRouter(AdminrateView)
