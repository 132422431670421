import meh from '../assets/images/mood-meh.svg';
import bad from '../assets/images/mood-bad.svg';
import good from '../assets/images/mood-good.svg';
import great from '../assets/images/mood-great.svg';

export const moods = [
  {
    backgroundColor: 'rgb(40, 72, 80)',
    image: bad,
    title: 'Bad',
    id: 'bad'
  },{
    backgroundColor: 'rgb(166, 210, 213)',
    image: meh,
    title: 'Meh',
    id: 'meh'
  },{
    backgroundColor: 'rgb(247, 154, 121)',
    image: good,
    title: 'Good',
    id: 'good'
  },{
    backgroundColor: 'rgb(243, 99, 48)',
    image: great,
    title: 'Great',
    id: 'great'
  },
]
