import React from 'react';

import { withRouter } from "react-router";
import useCookie from 'react-use-cookie'
import { environment } from 'config/environment'
import { connect } from 'react-redux'
import {
    actionToProps as authAction
} from 'store/reducers/auth/auth.action'
import {
    actionToProps as newsAction
} from 'store/reducers/news/news.action'

import Loading from 'components/Loading'
import SnackbarAlert from 'components/Alert/Snackbar'
import KindredLogoAuth from 'components/KindredLogo/Auth'
// import {
//   apiPostSignin,
// } from 'api/login.api'
import { routerPath } from 'router/Routerlist'
import mailIcon from "../../assets/images/icon-mail.svg"
import lockIcon from "../../assets/images/icon-lock.svg"
// import { errorValidationHandler } from 'utils/errorHandler'


const SigninView = (props) => {
    const formInput = React.useRef(null)
    const [userToken, setUserToken] = useCookie(environment.ACCESS_TOKEN_COOKIE, '0');

    const {
        actionPostSignIn,
        news: {
          errors,
          alertSeverity,
          isPageLoading,
        },
        // auth: {
        //   authenticated
        // },
        history,
        changeNewsAttribute,
        // changeAuthAttribute,
    } = props
    React.useEffect(()=> {

      changeNewsAttribute({
        key: 'isPageLoading',
        value: false
      })
      changeNewsAttribute({
        key: 'errors',
        value: []
      })
    }, [changeNewsAttribute])

    const handleSubmit = async (e) => {
      e.preventDefault()

      const payload = {
        username: formInput.current.querySelector('input#email').value,
        password: formInput.current.querySelector('input#password').value,
      }

      const success = await actionPostSignIn(payload)
      if (success){
        setUserToken(success.token)
        const redirectLink = success.user.is_admin || success.user.is_organization_admin ? routerPath.user.dashboard : routerPath.user.rate
        setTimeout(()=> {
          history.push(redirectLink)
        },300)
      }

    }


    return (
        <>
            <div className='wrapper auth-bg'>
                <div className="sm-container">
                    <div className="sm-container-inner">
                        <KindredLogoAuth />
                        <form ref={formInput} noValidate onSubmit={handleSubmit} className="form-input">
                        <div className="sm-container-content">
                            <div className="nav-tab">
                                <div className="nav-item" onClick={()=> history.push('/auth/signup')}>Sign up</div>
                                <div className="nav-item active"  onClick={()=> history.push('/auth/signin')}>Sign in</div>
                            </div>
                            <div className="form-content">
                                <div className="mb-2">
                                    <label>Email</label>
                                    <div className="form-inline-group">
                                        <span>
                                            <img src={mailIcon} alt="" />
                                        </span>
                                        <input id="email" type="text" className="form-control" placeholder="Enter your email" />
                                    </div>
                                </div>
                                <div className="mb-2">
                                    <label>Password</label>
                                    <div className="form-inline-group">
                                        <span>
                                            <img src={lockIcon} alt="" />
                                        </span>
                                        <input id="password" type="password" className="form-control" placeholder="Enter password" />
                                    </div>
                                </div>
                                <div className='mui--text-right mb-2'>

                                    <div className='link link-color' onClick={()=> history.push('/auth/forgot_password')}>
                                        Forgot Password
                                    </div>
                                </div>
                                <button className="btn btn-primary btn-block">Sign In</button>

                            </div>
                        </div>
                        </form>
                    </div>
                </div>
            </div>

            <SnackbarAlert severity={alertSeverity}  isOpen={errors.length ? true : false} handleClose={()=> {
              changeNewsAttribute({
                key: 'errors',
                value: []
              })
            }}>
              {typeof errors === 'object' && errors.length && errors.map((err, k)=> <li key={k}>{err}</li>)}
            </SnackbarAlert>
          <Loading open={isPageLoading} />
        </>
    );
}


const mapStateToProps = (state) => {
    return {
        news: state.news,
        auth: state.auth
    }
}
const mapDispatchToProps = {
  ...newsAction,
  ...authAction
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SigninView))
