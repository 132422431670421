import React from 'react';
import { withRouter } from "react-router";
import { connect } from 'react-redux'
import {
    actionToProps as authAction
} from 'store/reducers/auth/auth.action'
import {
    actionToProps as newsAction
} from 'store/reducers/news/news.action'
import { routerPath } from 'router/Routerlist'

import Loading from 'components/Loading'
import SnackbarAlert from 'components/Alert/Snackbar'
import KindredLogoAuth from 'components/KindredLogo/Auth'
import mailIcon from "../../assets/images/icon-mail.svg"
import lockIcon from "../../assets/images/icon-lock.svg"
import OrganizationDropdownList from 'components/Organization/OptionList';
// import {
//   apiPostSignup,
// } from 'api/login.api'
// import { errorValidationHandler } from 'utils/errorHandler'


const SignupView = (props) => {
    const formInput = React.useRef(null)
    const [organizationId, organizationIdUpdate] = React.useState(null)
    const [hasReadTerms, setHasReadTerms] = React.useState(false)
    const {
        history,
        changeNewsAttribute,
        // changeAuthAttribute,
        actionPostSignUp,
        news: {
          alertSeverity,
          errors,
          isPageLoading
        },
    } = props

    const handleSubmit = async (e) => {
      e.preventDefault()

      const payload = {
        email: formInput.current.querySelector('input#email').value,
        password: formInput.current.querySelector('input#password').value,
        organization_id: organizationId,
        has_read_terms: hasReadTerms
      }

      const success = await actionPostSignUp(payload)
      if (success){
        setTimeout(()=> {
          history.push(routerPath.auth.token)
        },300)
      }
    }
    return (
        <>
            <div className='wrapper auth-bg'>
                <div className="sm-container">
                    <div className="sm-container-inner">
                        <KindredLogoAuth />
                        <form ref={formInput} noValidate onSubmit={handleSubmit} className="form-input">
                        <div className="sm-container-content">
                            <div className="nav-tab">
                                <div className="nav-item active" onClick={() => history.push(routerPath.auth.signup)}>Sign up</div>
                                <div className="nav-item" onClick={() => history.push(routerPath.auth.signin)}>Sign in</div>
                            </div>
                            <div className="form-content">
                                <div className="mb-2">
                                    <label>Organization</label>
                                    <div className="form-inline-group">
                                        <OrganizationDropdownList
                                            dropdownStyle={{maxHeight: 200, overflowY: 'scroll'}}
                                            onConfirm={(id)=> organizationIdUpdate(id)}/>
                                    </div>
                                </div>
                                <div className="mb-2">
                                    <label>Email</label>
                                    <div className="form-inline-group">
                                        <span>
                                            <img src={mailIcon} alt="" />
                                        </span>
                                        <input type="text" className="form-control" placeholder="Enter your email" id="email"/>
                                    </div>
                                </div>
                                <div className="mb-2">
                                    <label>Password</label>
                                    <div className="form-inline-group">
                                        <span>
                                            <img src={lockIcon} alt="" />
                                        </span>
                                        <input type="password" className="form-control" placeholder="Enter password" id="password" />
                                    </div>
                                </div>
                                <button className="btn btn-primary btn-block" type="submit">Sign up</button>
                                <div className='mui-checkbox check-sm'>
                                    <label>
                                        <input type="checkbox" value={hasReadTerms} onChange={(val)=> {
                                            setHasReadTerms(val.target.checked)
                                        }}/>
                                        I have read Terms and Conditions and Privacy Policy
                                    </label>
                                </div>
                            </div>
                        </div>
                        </form>
                    </div>
                </div>
            </div>

            <SnackbarAlert severity={alertSeverity}  isOpen={errors.length ? true : false} handleClose={()=> {
              changeNewsAttribute({
                key: 'errors',
                value: []
              })
            }}>
              {typeof errors === 'object' && errors.length && errors.map((err, k)=> <li key={k}>{err}</li>)}
            </SnackbarAlert>
          <Loading open={isPageLoading} />
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        news: state.news,
    }
}
const mapDispatchToProps = { ...authAction, ...newsAction }
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignupView))
