import React from 'react';
import { withRouter } from "react-router";
import classNames from 'classnames'
import useCookie from 'react-use-cookie'
import { environment } from 'config/environment'
import { connect } from 'react-redux'
import {
    actionToProps as authAction
} from 'store/reducers/auth/auth.action'

import { routerPath } from 'router/Routerlist'
import homeIcon from '../../assets/images/icon-home.svg'
import userIcon from '../../assets/images/icon-user.svg'
import barIcon from '../../assets/images/icon-bar.svg'
import signOutIcon from '../../assets/images/icon-signout.svg'
import { useCookies } from 'react-cookie'

const activatePage = {
  [routerPath.user.moodDetail]: 'statistics',
  [routerPath.user.rate]: 'home',
  [routerPath.user.upload]: 'home',
  [routerPath.user.adminstatistics]: 'statistics',
  [routerPath.user.dashboard]: 'home',
  [routerPath.user.profile]: 'profile',
  [routerPath.user.statistics]: 'statistics'
}
const SideBar = (props) => {
  const [userToken, setUserToken] = useCookie(environment.ACCESS_TOKEN_COOKIE, '0');
  const [hideMenu, setHideMenu] = React.useState(true)
  const [activePage, setActivePage] = React.useState('')
  const [cookies, setCookie, removeCookie] = useCookies(['access_token']);
  const {
    auth: {
      authenticated
    },
    history,
    location,
    changeAuthAttribute
  } = props
  const linkHome = authenticated && authenticated.is_admin || authenticated?.is_organization_admin  ? routerPath.user.dashboard : routerPath.user.rate
  const linkStatistic = authenticated && authenticated.is_admin || authenticated?.is_organization_admin  ? routerPath.user.adminstatistics : routerPath.user.moodDetail

  React.useEffect(() => {
    const active = activatePage[location.pathname] ? activatePage[location.pathname] : ''
    setActivePage(active)
  }, [location.pathname])
  return (
    // <div className="sidebar">
    <div className={classNames({
      "sidebar sidebar-open": true,
      "sidebar-close": hideMenu
    })}>
      <div className='nav-menu'>
        <div className="menu-bar cursor-pointer" onClick={()=> setHideMenu(!hideMenu)}>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div className={classNames({
        'active': activePage === 'home',
        'link': true,
      })} onClick={() => history.push(linkHome)}>
        <span className='sidebarico'><img src={homeIcon} className="img-fluid" alt="" /></span>
        <span className='sidebarlabel'>Home</span>
      </div>
      <div className={classNames({
        'active': activePage === 'profile',
        'link': true,
      })} onClick={() => history.push(routerPath.user.profile)}>
        <span className='sidebarico'><img src={userIcon} className="img-fluid" alt="" /></span>
        <span className='sidebarlabel'>Profile</span>
      </div>
      <div className={classNames({
        'active': activePage === 'statistics',
        'link': true,
      })} onClick={() => history.push(linkStatistic)}>
        <span className='sidebarico'><img src={barIcon} className="img-fluid" alt="" /></span>
        <span className='sidebarlabel'>Statistics</span>
      </div>
      <div className={classNames({
        'link hide': true,
      })} onClick={() => history.push(routerPath.user.adminrate)}>
        <span className='sidebarico'><img src="/images/icon-bar.svg" className="img-fluid" alt="" /></span>
        <span className='sidebarlabel'>Daily log</span>
      </div>
      <div className={classNames({
        'link hide': true,
      })} onClick={() => history.push(routerPath.user.rate)}>
        <span className='sidebarico'><img src="/images/icon-bar.svg" className="img-fluid" alt="" /></span>
        <span className='sidebarlabel'>Mood</span>
      </div>
      <div className="link" onClick={() => {
        removeCookie(environment.ACCESS_TOKEN_COOKIE, { path: '/'})
        window.localStorage.removeItem('persist:root')
        changeAuthAttribute({
          key: 'authenticated',
          value: null
        }).then(()=> {
          history.push(routerPath.auth.signin)
        })
      }}>
        <span className='sidebarico'><img src={signOutIcon} className="img-fluid" alt="" /></span>
        <span className='sidebarlabel'>Logout</span>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}
const mapDispatchToProps = { ...authAction }
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SideBar))
