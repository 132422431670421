import React, { useState, useRef } from 'react';

import { withRouter } from "react-router";
import KindredLogoAuth from 'components/KindredLogo/Auth'
import { connect } from 'react-redux'
import {
    actionToProps as newsAction
} from 'store/reducers/news/news.action'
import { routerPath } from 'router/Routerlist'
import Loading from 'components/Loading'
import SnackbarAlert from 'components/Alert/Snackbar'
import SideBarDashboard from 'components/SideBar/Dashboard'
import profileIcon from "../../assets/images/icon-profile.svg"
import mailIcon from "../../assets/images/icon-mail.svg"
import tagIcon from "../../assets/images/icon-tag.svg"
import OrganizationDropdownList from 'components/Organization/OptionList';

const ProfileView = (props) => {
  const formInput = useRef(null)
  const {
    history,
    fetchProfile,
    changeNewsAttribute,
    putProfileOrganization,
    news: {
      userProfile,
      alertSeverity,
      errors,
      isPageLoading
    },
    auth: {
      authenticated
    }
  } = props
  const [formProfile, setFormProfile ] = useState(userProfile)
  const [editable, setEditable] = useState(false)
  const [inputOrganizationId, setInputOrganizationId] = useState(null)

  const {
    name,
    organization,
    email
  } = formProfile

  React.useEffect(()=> {
    fetchProfile({id: authenticated?.id})
  }, [authenticated?.id, fetchProfile])

  React.useEffect(()=> {
    setFormProfile(userProfile)
  }, [userProfile])

  const redirectLink = authenticated && authenticated.is_admin ? routerPath.user.dashboard : routerPath.user.rate;

  const handleSubmit = async (e) => {
    e.preventDefault()

    const payload = {
      email: formInput.current.querySelector('input#email').value,
      name: formInput.current.querySelector('input#fullName').value,
      organization: formProfile.organization?.id ? formProfile?.organization?.id : inputOrganizationId,
      id: authenticated.id
    }
    const success = await putProfileOrganization(payload);
    if (success){
      setEditable(false)
    }
  }


return (
<>
    <div className='main-wrapper-content  auth-bg'>
        <SideBarDashboard />
        <div className="sm-container profile-screen" style={{maxWidth: 'unset', width: '40vw'}}>
            <div className="sm-container-inner">
                <KindredLogoAuth page="profile" redirectLink={() => history.push(redirectLink)}/>
                <div className="sm-container-content">
                <form ref={formInput} noValidate onSubmit={handleSubmit} className="form-input">
                    <div className="form-content">
                        <div className='text-center mb-2'>
                            <h3 className="mt-0">Profile</h3>
                        </div>

                        <div className="mb-2">
                            <label>Full Name</label>
                            <div className="form-inline-group">
                                <span>
                                    <img src={profileIcon} alt="" />
                                </span>
                                <input type="text" value={name || ''}
                                  onChange={(e)=> setFormProfile({
                                    ...formProfile,
                                    name: e.target.value
                                  })}
                                  className="form-control" placeholder="Your full name" id="fullName" disabled={!editable}/>
                            </div>
                        </div>
                        <div className="mb-2">
                            <label>Email</label>
                            <div className="form-inline-group">
                                <span>
                                    <img src={mailIcon} alt="" />
                                </span>
                                <input type="email"
                                  value={email || ''}
                                  onChange={(e)=> setFormProfile({
                                    ...formProfile,
                                    email: e.target.value
                                  })}
                                  className="form-control" placeholder="Enter your email" id="email" disabled={!editable}/>
                            </div>
                        </div>
                        <div className="mb-2">
                            <label>Organization</label>
                            <div className="form-inline-group">
                                <span>
                                    <img src={tagIcon} alt="" />
                                </span>
                                <OrganizationDropdownList
                                  defaultTitle={organization?.name}
                                  onConfirm={(id)=> {
                                    setInputOrganizationId(id)
                                  }}
                                  dropdownStyle={{maxHeight: 130, overflowY: 'scroll'}}
                                  inputStyle={{paddingLeft: 40}}/>
                            </div>
                        </div>


                        <button className={`btn ${!editable ? 'btn-disabled' : 'btn-primary'} btn-block mb-1`} disabled={!editable}>Submit</button>
                        <div className="mui-row">
                            { !editable && (
                              <div className="mui-col-xs-6 pr-1">
                              <button type="button" className="btn btn-outline-primary btn-block mb-1"
                                onClick={()=> setEditable(true)}>Edit</button>
                              </div>
                            )}
                            <div className={`mui-col-xs-${editable ? '12' : '6'}`}>
                                <div className="btn btn-outline-primary btn-block mb-1"
                                  onClick={()=> {
                                    return !editable ? history.push(redirectLink) : setEditable(false) 
                                  }}>Cancel</div>
                            </div>
                        </div>

                    </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <SnackbarAlert severity={alertSeverity}  isOpen={errors.length ? true : false} handleClose={()=> {
      changeNewsAttribute({
        key: 'errors',
        value: []
      })
    }}>
      {errors.length && errors.map((err, k)=> <li key={k}>{err}</li>)}
    </SnackbarAlert>
  <Loading open={isPageLoading} />
</>
);
}

const mapStateToProps = (state) => {
    return {
        news: state.news,
        auth: state.auth,
    }
}
const mapDispatchToProps = {
  ...newsAction
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProfileView))
