import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    // Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend
);


const BarChartLegend = (props) => {
  const {
    data,
  } = props


  const graphOptions = {
      scales: {
        y: {
          ticks: {
            callback: function(value, index, ticks) {
                return `${value} %`;
            }
          },
          min: 1,
          max: 100,
          stepSize: 10,
          title: {
            display: true,
            text: 'In Percentage',
          }
        }
      },
      // responsive: true,
      maintainAspectRatio: false,
      aspectRatio: 2,
      plugins: {
        tooltip: {
          callbacks: {
            label: (context) => {
              let label = "";
              if (context.parsed.y) {
                label = context.parsed.y + "%"
              }

              return `${context.dataset.label} ${label}`;
            }
          }
        },
      },

  };


  return (
    <Bar options={graphOptions} data={data} />
  )
}

export default BarChartLegend
