import axios from 'axios'
import { setupAxios } from 'utils/axiosConfig'
import { apiToken } from 'api/self'


export const signinApi = {
  signin: "/api/v1/login/",
  signup: "/api/v1/signup/",
  resetpassword: "/users/reset-password/send/",
  tokenInputVerifyResetPassword: "/users/reset-password/verify/",
  tokenInputVerifySignup: "/users/email/verify-user/",
};

export const apiPostSigninFetch = async (payload) => {
  return await apiToken(signinApi.signin, {
    body: payload,
    method: 'post'
  })
}

export const apiPostSignin = async (payload) => {
  setupAxios()
  return axios.post(signinApi.signin, payload)
}

export const apiPostSignup = async (payload) => {
  setupAxios()
  return axios.post(signinApi.signup, payload)
}

export const apiPostResetPassword = async (payload) => {
  setupAxios()
  return axios.post(signinApi.resetpassword, payload)
}

export const apiPostTokenInput = async (payload) => {
  setupAxios()
  return axios.post(signinApi.tokenInputVerifyResetPassword, payload)
}
export const apiPostTokenVerifySignup = async (payload) => {
  setupAxios()
  return axios.post(signinApi.tokenInputVerifySignup, payload)
}
