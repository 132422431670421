import React from 'react';
import Dialog from '@mui/material/Dialog';
import closeIcon from "../../assets/images/icon-close.svg"
import profileIcon from "../../assets/images/icon-profile.svg"
import mailIcon from "../../assets/images/icon-mail.svg"
import OrganizationDropdownList from 'components/Organization/OptionList';

const ModalNewEmploye = (props) => {
  const formInput = React.useRef(null)
  const [organizationId, organizationIdUpdate] = React.useState(null)
  const {
    fullWidth,
    maxWidth,
    open,
    handleClose,
    handleConfirm,
  } = props

  const handleSubmit = async (e) => {
    e.preventDefault()

    const payload = {
      name: formInput.current.querySelector('input#name').value,
      email: formInput.current.querySelector('input#email').value,
      organization_id: organizationId
    }

    handleConfirm(payload)
  }
  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      onClose={handleClose}
    >
      <button className="btn btn-close" onClick={handleClose}><img src={closeIcon} alt="" /></button>
      <div className="modal">
        <div className="modal-container">
          <div className="modal-content">
            <h3 className="mt-0 text-center mb-3">
              Add new employee
            </h3>
            <form ref={formInput} noValidate onSubmit={handleSubmit} className="form-input">
              <div className="mb-2">
                <label>Organization</label>
                <div className="form-inline-group">
                  <OrganizationDropdownList onConfirm={(id)=> organizationIdUpdate(id)}/>
                </div>
              </div>
              <div className="mb-2">
                <label>Name</label>
                <div className="form-inline-group">
                  <span>
                    <img src={profileIcon} alt="" />
                  </span>
                  <input type="text" className="form-control" placeholder="Name" id="name" />
                </div>
              </div>
              <div className="mb-2">
                <label>Email</label>
                <div className="form-inline-group">
                  <span>
                    <img src={mailIcon} alt="" />
                  </span>
                  <input type="email" className="form-control" placeholder="Email" id="email" />
                </div>
              </div>
              <button className="btn btn-primary btn-block mb-2" type="submit">Add new employee</button>
            </form>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
ModalNewEmploye.defaultProps = {
  open: false,
  maxWidth: 'md',
  fullWidth: false,
  handleClose: () => false,
  handleConfirm: () => false
}
export default ModalNewEmploye
